import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ContentContainer from "../components/contentcontainer"
import SEO from "../components/seo"

export default function Privacy({ data }) {

  return (
    <div>
      <Layout siteTitle="Datenschutzerklärung">
        <SEO
          title="Datenschutzerklärung | Babyfotografie Köln"
          description="Fotografin für ihre natürlichen und liebevollen Babybauch- und Babyfotos in Köln und Umgebung. Reserviert euren Termin jetzt online. Viele Bilder, Preise und Infos."
        />
        <ContentContainer>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </ContentContainer>
      </Layout>
    </div>
  )
}

/*
  Diese Funktion holt sich mit GraphQL den Markdown wird gefiltert nach dem Slug-Tag in der Datei
  in "html" ist dann das geparste Markdown als HTML String zu finden
  Übergeben werden die Daten an die Seitenfunktion über "data".
  Der Inhalt von Data lässt sich dann über {data.xxx} verwenden.
  Der HTML-String lässt sich über dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} injezieren
*/
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: {slug: {eq: "/content/privacy"}}) {
      frontmatter {
        date
        slug
        title
      }
      html
      fileAbsolutePath
    }
  }
`